const actions = {
  GET_MEMBERSHIPS_BY_LOCATION_BEGIN: 'GET_MEMBERSHIPS_BY_LOCATION_BEGIN',
  GET_MEMBERSHIPS_BY_LOCATION_SUCCESS: 'GET_MEMBERSHIPS_BY_LOCATION_SUCCESS',
  GET_MEMBERSHIPS_BY_LOCATION_ERR: 'GET_MEMBERSHIPS_BY_LOCATION_ERR',

  GET_INVOICES_BEGIN: 'GET_INVOICES_BEGIN',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_ERR: 'GET_INVOICES_ERR',

  GET_INVOICE_CARDS_BEGIN: 'GET_INVOICE_CARDS_BEGIN',
  GET_INVOICE_CARDS_SUCCESS: 'GET_INVOICE_CARDS_SUCCESS',
  GET_INVOICE_CARDS_ERR: 'GET_INVOICE_CARDS_ERR',

  GET_REVENUE_FORECAST_BEGIN: 'GET_REVENUE_FORECAST_BEGIN',
  GET_REVENUE_FORECAST_SUCCESS: 'GET_REVENUE_FORECAST_SUCCESS',
  GET_REVENUE_FORECAST_ERR: 'GET_REVENUE_FORECAST_ERR',

  getMembershipsByLocationBegin: () => {
    return {
      type: actions.GET_MEMBERSHIPS_BY_LOCATION_BEGIN,
    };
  },

  getMembershipsByLocationSuccess: (data) => {
    return {
      type: actions.GET_MEMBERSHIPS_BY_LOCATION_SUCCESS,
      data,
    };
  },

  getMembershipsByLocationErr: (err) => {
    return {
      type: actions.GET_MEMBERSHIPS_BY_LOCATION_ERR,
      err,
    };
  },

  getInvoicesBegin: () => {
    return {
      type: actions.GET_INVOICES_BEGIN,
    };
  },

  getInvoicesSuccess: (data) => {
    return {
      type: actions.GET_INVOICES_SUCCESS,
      data,
    };
  },

  getInvoicesErr: (err) => {
    return {
      type: actions.GET_INVOICES_ERR,
      err,
    };
  },

  getInvoiceCardsBegin: () => {
    return {
      type: actions.GET_INVOICE_CARDS_BEGIN,
    };
  },

  getInvoiceCardsSuccess: (data) => {
    return {
      type: actions.GET_INVOICE_CARDS_SUCCESS,
      data,
    };
  },

  getInvoiceCardsErr: (err) => {
    return {
      type: actions.GET_INVOICE_CARDS_ERR,
      err,
    };
  },

  getRevenueForecastBegin: () => {
    return {
      type: actions.GET_REVENUE_FORECAST_BEGIN,
    };
  },

  getRevenueForecastSuccess: (data) => {
    return {
      type: actions.GET_REVENUE_FORECAST_SUCCESS,
      data,
    };
  },

  getRevenueForecastErr: (err) => {
    return {
      type: actions.GET_REVENUE_FORECAST_ERR,
      err,
    };
  },
};

export default actions;
