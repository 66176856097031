const actions = {
    GET_LOCATIONS_BEGIN: 'GET_LOCATIONS_BEGIN',
    GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
    GET_LOCATIONS_ERR: 'GET_LOCATIONS_ERR',

    CREATE_LOCATION_BEGIN: 'CREATE_LOCATION_BEGIN',
    CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
    CREATE_LOCATION_ERR: 'CREATE_LOCATION_ERR',

    SELECT_LOCATION_SUCCESS: 'SELECT_LOCATION_SUCCESS',


    getLocationsBegin: () => {
        return {
            type: actions.GET_LOCATIONS_BEGIN
        }
    },

    getLocationsSuccess: data => {
        return {
            type: actions.GET_LOCATIONS_SUCCESS,
            data
        }
    },

    getLocationsErr: err => {
        return {
            type: actions.GET_LOCATIONS_ERR,
            err
        }
    },

    SelectLocationSuccess: data => {
        return {
            type: actions.SELECT_LOCATION_SUCCESS,
            data
        }
    },
    createLocationBegin: () => {
        return {
            type: actions.CREATE_LOCATION_BEGIN
        }
    },

    createLocationSuccess: data => {
        return {
            type: actions.CREATE_LOCATION_SUCCESS,
            data
        }
    },

    createLocationErr: err => {
        return {
            type: actions.CREATE_LOCATION_ERR,
            err
        }
    }

}

export default actions
