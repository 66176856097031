const actions = {
  CREATE_OFFER_BEGIN: 'CREATE_OFFER_BEGIN',
  CREATE_OFFER_SUCCESS: 'CREATE_OFFER_SUCCESS',
  CREATE_OFFER_ERR: 'CREATE_OFFER_ERR',

  GET_ALL_ACTIVE_OFFERS_BEGIN: 'GET_ALL_ACTIVE_OFFERS_BEGIN',
  GET_ALL_ACTIVE_OFFERS_SUCCESS: 'GET_ALL_ACTIVE_OFFERS_SUCCESS',
  GET_ALL_ACTIVE_OFFERS_ERR: 'GET_ALL_ACTIVE_OFFERS_ERR',

  GET_ALL_EXPIRED_OFFERS_BEGIN: 'GET_ALL_EXPIRED_OFFERS_BEGIN',
  GET_ALL_EXPIRED_OFFERS_SUCCESS: 'GET_ALL_EXPIRED_OFFERS_SUCCESS',
  GET_ALL_EXPIRED_OFFERS_ERR: 'GET_ALL_EXPIRED_OFFERS_ERR',

  EDIT_OFFER_BEGIN: 'EDIT_OFFER_BEGIN',
  EDIT_OFFER_SUCCESS: 'EDIT_OFFER_SUCCESS',
  EDIT_OFFER_ERR: 'EDIT_OFFER_ERR',

  DELETE_OFFER_BEGIN: 'DELETE_OFFER_BEGIN',
  DELETE_OFFER_SUCCESS: 'DELETE_OFFER_SUCCESS',
  DELETE_OFFER_ERR: 'DELETE_OFFER_ERR',

  createOfferBegin: () => {
    return {
      type: actions.CREATE_OFFER_BEGIN,
    };
  },
  createOfferSuccess: (data) => {
    return {
      type: actions.CREATE_OFFER_SUCCESS,
      data,
    };
  },
  createOfferErr: (err) => {
    return {
      type: actions.CREATE_OFFER_ERR,
      err,
    };
  },

  getAllActiveOffersBegin: () => {
    return {
      type: actions.GET_ALL_ACTIVE_OFFERS_BEGIN,
    };
  },
  getAllActiveOffersSuccess: (data) => {
    return {
      type: actions.GET_ALL_ACTIVE_OFFERS_SUCCESS,
      data,
    };
  },
  getAllActiveOffersErr: (err) => {
    return {
      type: actions.GET_ALL_ACTIVE_OFFERS_ERR,
      err,
    };
  },

  getAllExpiredOffersBegin: () => {
    return {
      type: actions.GET_ALL_EXPIRED_OFFERS_BEGIN,
    };
  },
  getAllExpiredOffersSuccess: (data) => {
    return {
      type: actions.GET_ALL_EXPIRED_OFFERS_SUCCESS,
      data,
    };
  },
  getAllExpiredOffersErr: (err) => {
    return {
      type: actions.GET_ALL_EXPIRED_OFFERS_ERR,
      err,
    };
  },

  editOfferBegin: () => {
    return {
      type: actions.EDIT_OFFER_BEGIN,
    };
  },
  editOfferSuccess: (data) => {
    return {
      type: actions.EDIT_OFFER_SUCCESS,
      data,
    };
  },
  editOfferErr: (err) => {
    return {
      type: actions.EDIT_OFFER_ERR,
      err,
    };
  },

  deleteOfferBegin: () => {
    return {
      type: actions.DELETE_OFFER_BEGIN,
    };
  },
  deleteOfferSuccess: (data) => {
    return {
      type: actions.DELETE_OFFER_SUCCESS,
      data,
    };
  },
  deleteOfferErr: (err) => {
    return {
      type: actions.DELETE_OFFER_ERR,
      err,
    };
  },
};

export default actions;
