// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth';
import navbar from './navbar';
import layout from './layout';
import authReducer from '../authentication/reducers';
import meetingRooms from '../meetingRooms/reducers';
import locations from '../location/reducers';
import memberships from '../membership/reducers';
import invoices from '../invoices/reducers';
import issues from '../issues/reducers';
import offers from '../offers/reducers';
const rootReducer = combineReducers({
  auth,
  navbar,
  authReducer,
  meetingRooms,
  memberships,
  locations,
  invoices,
  issues,
  offers,
  layout,
});

export default rootReducer;
