import actions from './actions';

const {
  GET_LOCATIONS_BEGIN,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERR,

  CREATE_LOCATION_BEGIN,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_ERR,

  SELECT_LOCATION_SUCCESS,
} = actions;

const initState = {
  locations: null,
  selectedLocation: JSON.parse(localStorage.getItem('location')),
  loading: false,
  error: null,
};
const LocationReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_LOCATIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: data,
        loading: false,
      };
    }
    case GET_LOCATIONS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SELECT_LOCATION_SUCCESS: {
      const selectedLocation = state.locations.filter((item) => item._id === data);
      localStorage.setItem('location', JSON.stringify(selectedLocation[0]));
      return {
        ...state,
        selectedLocation: selectedLocation[0],
      };
    }
    case CREATE_LOCATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: data,
        loading: false,
      };
    case CREATE_LOCATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default LocationReducer;
