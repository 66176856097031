import actions from './actions';

const {
  GET_ALL_UNRESOLVED_ISSUES_BEGIN,
  GET_ALL_UNRESOLVED_ISSUES_SUCCESS,
  GET_ALL_UNRESOLVED_ISSUES_ERR,

  GET_ALL_RESOLVED_ISSUES_BEGIN,
  GET_ALL_RESOLVED_ISSUES_SUCCESS,
  GET_ALL_RESOLVED_ISSUES_ERR,

  RESOLVE_REPORTED_ISSUE_BEGIN,
  RESOLVE_REPORTED_ISSUE_SUCCESS,
  RESOLVE_REPORTED_ISSUE_ERR,

  GET_POST_DETAILS_BEGIN,
  GET_POST_DETAILS_SUCCESS,
  GET_POST_DETAILS_ERR,
} = actions;

const initState = {
  allUnresolvedIssues: null,
  allResolvedIssues: null,
  loading: false,
  resolveReportLoading: false,
  unresolvedLoading: false,
  resolvedLoading: false,
  error: null,
  unresolvedError: null,
  resolvedError: null,
  postDetails: null,
};
const IssuesReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_ALL_UNRESOLVED_ISSUES_BEGIN:
      return {
        ...state,
        unresolvedLoading: true,
      };
    case GET_ALL_UNRESOLVED_ISSUES_SUCCESS: {
      return {
        ...state,
        allUnresolvedIssues: data,
        unresolvedLoading: false,
      };
    }
    case GET_ALL_UNRESOLVED_ISSUES_ERR:
      return {
        ...state,
        unresolvedError: err,
        unresolvedLoading: false,
      };

    case GET_ALL_RESOLVED_ISSUES_BEGIN:
      return {
        ...state,
        resolvedLoading: true,
      };
    case GET_ALL_RESOLVED_ISSUES_SUCCESS: {
      return {
        ...state,
        allResolvedIssues: data,
        resolvedLoading: false,
      };
    }
    case GET_ALL_RESOLVED_ISSUES_ERR:
      return {
        ...state,
        resolvedError: err,
        resolvedLoading: false,
      };

    case RESOLVE_REPORTED_ISSUE_BEGIN:
      return {
        ...state,
        resolveReportLoading: true,
      };
    case RESOLVE_REPORTED_ISSUE_SUCCESS: {
      return {
        ...state,
        resolveReportLoading: false,
      };
    }
    case RESOLVE_REPORTED_ISSUE_ERR:
      return {
        ...state,
        error: err,
        resolveReportLoading: false,
      };

    case GET_POST_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_POST_DETAILS_SUCCESS: {
      return {
        ...state,
        postDetails: data,
        loading: false,
      };
    }
    case GET_POST_DETAILS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export default IssuesReducer;
