const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  SEND_OTP_BEGIN: 'SEND_OTP_BEGIN',
  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  SEND_OTP_ERR: 'SEND_OTP_ERR',

  VALIDATE_OTP_BEGIN: 'VALIDATE_OTP_BEGIN',
  VALIDATE_OTP_SUCCESS: 'VALIDATE_OTP_SUCCESS',
  VALIDATE_OTP_ERR: 'VALIDATE_OTP_ERR',

  RESET_PASSWORD_BEGIN: 'RESET_PASSWORD_BEGIN',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERR: 'RESET_PASSWORD_ERR',

  CREATE_ADMIN_BEGIN: 'CREATE_ADMIN_BEGIN',
  CREATE_ADMIN_SUCCESS: 'CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_ERR: 'CREATE_ADMIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  INVITE_ADMIN_BEGIN: 'INVITE_ADMIN_BEGIN',
  INVITE_ADMIN_SUCCESS: 'INVITE_ADMIN_SUCCESS',
  INVITE_ADMIN_ERR: 'INVITE_ADMIN_ERR',

  SEND_RESET_PASSWORD_BEGIN: 'SEND_RESET_PASSWORD_BEGIN',
  SEND_RESET_PASSWORD_SUCCESS: 'SEND_RESET_PASSWORD_SUCCESS',
  SEND_RESET_PASSWORD_ERR: 'SEND_RESET_PASSWORD_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  sendOTPBegin: () => {
    return {
      type: actions.SEND_OTP_BEGIN,
    };
  },

  sendOTPSuccess: (data) => {
    return {
      type: actions.SEND_OTP_SUCCESS,
      data,
    };
  },

  sendOTPErr: (err) => {
    return {
      type: actions.SEND_OTP_ERR,
      err,
    };
  },

  validateOTPBegin: () => {
    return {
      type: actions.VALIDATE_OTP_BEGIN,
    };
  },

  validateOTPSuccess: (data) => {
    return {
      type: actions.VALIDATE_OTP_SUCCESS,
      data,
    };
  },

  validateOTPErr: (err) => {
    return {
      type: actions.VALIDATE_OTP_ERR,
      err,
    };
  },

  resetPasswordBegin: () => {
    return {
      type: actions.RESET_PASSWORD_BEGIN,
    };
  },

  resetPasswordSuccess: (data) => {
    return {
      type: actions.RESET_PASSWORD_SUCCESS,
      data,
    };
  },

  resetPasswordErr: (err) => {
    return {
      type: actions.RESET_PASSWORD_ERR,
      err,
    };
  },

  createAdminBegin: () => {
    return {
      type: actions.CREATE_ADMIN_BEGIN,
    };
  },

  createAdminSuccess: (data) => {
    return {
      type: actions.CREATE_ADMIN_SUCCESS,
      data,
    };
  },

  createAdminErr: (err) => {
    return {
      type: actions.CREATE_ADMIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  inviteAdminBegin: () => {
    return {
      type: actions.INVITE_ADMIN_BEGIN,
    };
  },

  inviteAdminSuccess: (data) => {
    return {
      type: actions.INVITE_ADMIN_SUCCESS,
      data,
    };
  },

  inviteAdminErr: (err) => {
    return {
      type: actions.INVITE_ADMIN_ERR,
      err,
    };
  },

  sendResetPasswordBegin: () => {
    return {
      type: actions.SEND_RESET_PASSWORD_BEGIN,
    };
  },

  sendResetPasswordSuccess: (data) => {
    return {
      type: actions.SEND_RESET_PASSWORD_SUCCESS,
      data,
    };
  },

  sendResetPasswordErr: (err) => {
    return {
      type: actions.SEND_RESET_PASSWORD_ERR,
      err,
    };
  },
};

export default actions;
