import actions from './actions';

const {
  CREATE_OFFER_BEGIN,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_ERR,

  GET_ALL_ACTIVE_OFFERS_BEGIN,
  GET_ALL_ACTIVE_OFFERS_SUCCESS,
  GET_ALL_ACTIVE_OFFERS_ERR,

  GET_ALL_EXPIRED_OFFERS_BEGIN,
  GET_ALL_EXPIRED_OFFERS_SUCCESS,
  GET_ALL_EXPIRED_OFFERS_ERR,

  EDIT_OFFER_BEGIN,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_ERR,

  DELETE_OFFER_BEGIN,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_ERR,
} = actions;

const initState = {
  loading: false,
  createOfferLoading: false,
  error: null,
  activeOffersLoading: false,
  allActiveOffers: null,
  activeOffersError: null,
  expiredOffersLoading: false,
  allExpiredOffers: null,
  expiredOffersError: null,
};
const OffersReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_OFFER_BEGIN:
      return {
        ...state,
        createOfferLoading: true,
      };
    case CREATE_OFFER_SUCCESS: {
      return {
        ...state,
        createOfferLoading: false,
      };
    }
    case CREATE_OFFER_ERR:
      return {
        ...state,
        error: err,
        createOfferLoading: false,
      };

    case GET_ALL_ACTIVE_OFFERS_BEGIN:
      return {
        ...state,
        activeOffersLoading: true,
      };
    case GET_ALL_ACTIVE_OFFERS_SUCCESS: {
      return {
        ...state,
        allActiveOffers: data,
        activeOffersLoading: false,
      };
    }
    case GET_ALL_ACTIVE_OFFERS_ERR:
      return {
        ...state,
        activeOffersError: err,
        activeOffersLoading: false,
      };

    case GET_ALL_EXPIRED_OFFERS_BEGIN:
      return {
        ...state,
        expiredOffersLoading: true,
      };
    case GET_ALL_EXPIRED_OFFERS_SUCCESS: {
      return {
        ...state,
        allExpiredOffers: data,
        expiredOffersLoading: false,
      };
    }
    case GET_ALL_EXPIRED_OFFERS_ERR:
      return {
        ...state,
        expiredOffersError: err,
        expiredOffersLoading: false,
      };

    case EDIT_OFFER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case EDIT_OFFER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DELETE_OFFER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_OFFER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export default OffersReducer;
