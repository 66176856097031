import actions from './actions';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,

  SEND_OTP_BEGIN,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERR,

  VALIDATE_OTP_BEGIN,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_ERR,

  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERR,

  CREATE_ADMIN_BEGIN,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_ERR,

  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,

  INVITE_ADMIN_BEGIN,
  INVITE_ADMIN_SUCCESS,
  INVITE_ADMIN_ERR,

  SEND_RESET_PASSWORD_BEGIN,
  SEND_RESET_PASSWORD_SUCCESS,
  SEND_RESET_PASSWORD_ERR,
} = actions;

const initState = {
  login: localStorage.getItem('userData'),
  signup: null,
  loading: false,
  error: null,
  inviteAdmin: null,
  resetPassword: null,
  sendResetPassword: null,
};

const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SEND_OTP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        sendOtp: data,
        loading: false,
      };
    case SEND_OTP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VALIDATE_OTP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        sendOtp: data,
        loading: false,
      };
    case VALIDATE_OTP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RESET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: data,
        loading: false,
      };
    case RESET_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CREATE_ADMIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        sendOtp: data,
        loading: false,
      };
    case CREATE_ADMIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case INVITE_ADMIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case INVITE_ADMIN_SUCCESS:
      return {
        ...state,
        inviteAdmin: data,
        loading: false,
      };
    case INVITE_ADMIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SEND_RESET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SEND_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        sendResetPassword: data,
        loading: false,
      };
    case SEND_RESET_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
