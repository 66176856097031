const actions = {
  CREATE_MEMBERSHIP_BEGIN: 'CREATE_MEMBERSHIP_BEGIN',
  CREATE_MEMBERSHIP_SUCCESS: 'CREATE_MEMBERSHIP_SUCCESS',
  CREATE_MEMBERSHIP_ERR: 'CREATE_MEMBERSHIP_ERR',

  EDIT_MEMBERSHIP_BEGIN: 'EDIT_MEMBERSHIP_BEGIN',
  EDIT_MEMBERSHIP_SUCCESS: 'EDIT_MEMBERSHIP_SUCCESS',
  EDIT_MEMBERSHIP_ERR: 'EDIT_MEMBERSHIP_ERR',

  ADDENDUMS_MEMBERSHIP_BEGIN: 'ADDENDUMS_MEMBERSHIP_BEGIN',
  ADDENDUMS_MEMBERSHIP_SUCCESS: 'ADDENDUMS_MEMBERSHIP_SUCCESS',
  ADDENDUMS_MEMBERSHIP_ERR: 'ADDENDUMS_MEMBERSHIP_ERR',

  MARK_MEMBERSHIP_BEGIN: 'MARK_MEMBERSHIP_BEGIN',
  MARK_MEMBERSHIP_SUCCESS: 'MARK_MEMBERSHIP_SUCCESS',
  MARK_MEMBERSHIP_ERR: 'MARK_MEMBERSHIP_ERR',

  GET_ALL_MEMBERSHIPS_BEGIN: 'GET_ALL_MEMBERSHIPS_BEGIN',
  GET_ALL_MEMBERSHIPS_SUCCESS: 'GET_ALL_MEMBERSHIPS_SUCCESS',
  GET_ALL_MEMBERSHIPS_ERR: 'GET_ALL_MEMBERSHIPS_ERR',

  VIEW_MEMBERSHIP_BEGIN: 'VIEW_MEMBERSHIP_BEGIN',
  VIEW_MEMBERSHIP_SUCCESS: 'VIEW_MEMBERSHIP_SUCCESS',
  VIEW_MEMBERSHIP_ERR: 'VIEW_MEMBERSHIP_ERR',

  RESET_VIEW_MEMBERSHIP_DATA: 'RESET_VIEW_MEMBERSHIP_DATA',

  RENEW_MEMBERSHIP_BEGIN: 'RENEW_MEMBERSHIP_BEGIN',
  RENEW_MEMBERSHIP_SUCCESS: 'RENEW_MEMBERSHIP_SUCCESS',
  RENEW_MEMBERSHIP_ERR: 'RENEW_MEMBERSHIP_ERR',

  HISTORY_BEGIN: 'HISTORY_BEGIN',
  HISTORY_SUCCESS: 'HISTORY_SUCCESS',
  HISTORY_ERR: 'HISTORY_ERR',

  CREATE_MEMBER_BEGIN: 'CREATE_MEMBER_BEGIN',
  CREATE_MEMBER_SUCCESS: 'CREATE_MEMBER_SUCCESS',
  CREATE_MEMBER_ERR: 'CREATE_MEMBER_ERR',

  LIST_MEMBERSHIPS_BEGIN: 'LIST_MEMBERSHIPS_BEGIN',
  LIST_MEMBERSHIPS_SUCCESS: 'LIST_MEMBERSHIPS_SUCCESS',
  LIST_MEMBERSHIPS_ERR: 'LIST_MEMBERSHIPS_ERR',

  LIST_MEMBERS_BEGIN: 'LIST_MEMBERS_BEGIN',
  LIST_MEMBERS_SUCCESS: 'LIST_MEMBERS_SUCCESS',
  LIST_MEMBERS_ERR: 'LIST_MEMBERS_ERR',

  SET_MEMBERSHIP_PROFILE_RADIO: 'SET_MEMBERSHIP_PROFILE_RADIO',

  SET_CREATE_MEMBERSHIP_STEP: 'SET_CREATE_MEMBERSHIP_STEP',

  SET_MEMBERSHIP_FORM_DETAILS: 'SET_MEMBERSHIP_FORM_DETAILS',

  SET_EDIT_MEMBERSHIP_FORM_DETAILS: 'SET_EDIT_MEMBERSHIP_FORM_DETAILS',

  LIST_ALL_ADDENDUMS_BEGIN: 'LIST_ALL_ADDENDUMS_BEGIN',
  LIST_ALL_ADDENDUMS_SUCCESS: 'LIST_ALL_ADDENDUMS_SUCCESS',
  LIST_ALL_ADDENDUMS_ERR: 'LIST_ALL_ADDENDUMS_ERR',

  DELETE_ADDENDUM_BEGIN: 'DELETE_ADDENDUM_BEGIN',
  DELETE_ADDENDUM_SUCCESS: 'DELETE_ADDENDUM_SUCCESS',
  DELETE_ADDENDUM_ERR: 'DELETE_ADDENDUM_ERR',

  MEMBERSHIP_CARDS_BEGIN: 'MEMBERSHIP_CARDS_BEGIN',
  MEMBERSHIP_CARDS_SUCCESS: 'MEMBERSHIP_CARDS_SUCCESS',
  MEMBERSHIP_CARDS_ERR: 'MEMBERSHIP_CARDS_ERR',

  DELETE_MEMBERSHIP_BEGIN: 'DELETE_MEMBERSHIP_BEGIN',
  DELETE_MEMBERSHIP_SUCCESS: 'DELETE_MEMBERSHIP_SUCCESS',
  DELETE_MEMBERSHIP_ERR: 'DELETE_MEMBERSHIP_ERR',

  GET_ALL_WORKSPACE_MODULES_BEGIN: 'GET_ALL_WORKSPACE_MODULES_BEGIN',
  GET_ALL_WORKSPACE_MODULES_SUCCESS: 'GET_ALL_WORKSPACE_MODULES_SUCCESS',
  GET_ALL_WORKSPACE_MODULES_ERR: 'GET_ALL_WORKSPACE_MODULES_ERR',

  GENERATE_CREDITS_USAGE_REPORT_BEGIN: 'GENERATE_CREDITS_USAGE_REPORT_BEGIN',
  GENERATE_CREDITS_USAGE_REPORT_SUCCESS: 'GENERATE_CREDITS_USAGE_REPORT_SUCCESS',
  GENERATE_CREDITS_USAGE_REPORT_ERR: 'GENERATE_CREDITS_USAGE_REPORT_ERR',

  DEACTIVATE_USER_BEGIN: 'DEACTIVATE_USER_BEGIN',
  DEACTIVATE_USER_SUCCESS: 'DEACTIVATE_USER_SUCCESS',
  DEACTIVATE_USER_ERR: 'DEACTIVATE_USER_ERR',

  GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_BEGIN: 'GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_BEGIN',
  GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_SUCCESS: 'GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_SUCCESS',
  GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_ERR: 'GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_ERR',

  createMembershipBegin: () => {
    return {
      type: actions.CREATE_MEMBERSHIP_BEGIN,
    };
  },

  createMembershipSuccess: (data) => {
    return {
      type: actions.CREATE_MEMBERSHIP_SUCCESS,
      data,
    };
  },

  createMembershipErr: (err) => {
    return {
      type: actions.CREATE_MEMBERSHIP_ERR,
      err,
    };
  },

  editMembershipBegin: () => {
    return {
      type: actions.EDIT_MEMBERSHIP_BEGIN,
    };
  },

  editMembershipSuccess: (data) => {
    return {
      type: actions.EDIT_MEMBERSHIP_SUCCESS,
      data,
    };
  },

  editMembershipErr: (err) => {
    return {
      type: actions.EDIT_MEMBERSHIP_ERR,
      err,
    };
  },

  addendumsMembershipBegin: () => {
    return {
      type: actions.ADDENDUMS_MEMBERSHIP_BEGIN,
    };
  },

  addendumsMembershipSuccess: (data) => {
    return {
      type: actions.ADDENDUMS_MEMBERSHIP_SUCCESS,
      data,
    };
  },

  addendumsMembershipErr: (err) => {
    return {
      type: actions.ADDENDUMS_MEMBERSHIP_ERR,
      err,
    };
  },

  markMembershipBegin: () => {
    return {
      type: actions.MARK_MEMBERSHIP_BEGIN,
    };
  },

  markMembershipSuccess: (data) => {
    return {
      type: actions.MARK_MEMBERSHIP_SUCCESS,
      data,
    };
  },

  markMembershipErr: (err) => {
    return {
      type: actions.MARK_MEMBERSHIP_ERR,
      err,
    };
  },

  getAllMembershipsBegin: () => {
    return {
      type: actions.GET_ALL_MEMBERSHIPS_BEGIN,
    };
  },

  getAllMembershipsSuccess: (data) => {
    return {
      type: actions.GET_ALL_MEMBERSHIPS_SUCCESS,
      data,
    };
  },

  getAllMembershipsErr: (err) => {
    return {
      type: actions.GET_ALL_MEMBERSHIPS_ERR,
      err,
    };
  },

  viewMembershipBegin: () => {
    return {
      type: actions.VIEW_MEMBERSHIP_BEGIN,
    };
  },

  viewMembershipSuccess: (data) => {
    return {
      type: actions.VIEW_MEMBERSHIP_SUCCESS,
      data,
    };
  },

  viewMembershipErr: (err) => {
    return {
      type: actions.VIEW_MEMBERSHIP_ERR,
      err,
    };
  },

  resetViewMembershipData: () => {
    return {
      type: actions.RESET_VIEW_MEMBERSHIP_DATA,
    };
  },

  renewMembershipBegin: () => {
    return {
      type: actions.RENEW_MEMBERSHIP_BEGIN,
    };
  },

  renewMembershipSuccess: (data) => {
    return {
      type: actions.RENEW_MEMBERSHIP_SUCCESS,
      data,
    };
  },

  renewMembershipErr: (err) => {
    return {
      type: actions.RENEW_MEMBERSHIP_ERR,
      err,
    };
  },

  historyBegin: () => {
    return {
      type: actions.HISTORY_BEGIN,
    };
  },

  historySuccess: (data) => {
    return {
      type: actions.HISTORY_SUCCESS,
      data,
    };
  },

  historyErr: (err) => {
    return {
      type: actions.HISTORY_ERR,
      err,
    };
  },

  createMemberBegin: () => {
    return {
      type: actions.CREATE_MEMBER_BEGIN,
    };
  },

  createMemberSuccess: (data) => {
    return {
      type: actions.CREATE_MEMBER_SUCCESS,
      data,
    };
  },

  createMemberErr: (err) => {
    return {
      type: actions.CREATE_MEMBER_ERR,
      err,
    };
  },

  listMembershipsBegin: () => {
    return {
      type: actions.LIST_MEMBERSHIPS_BEGIN,
    };
  },

  listMembershipsSuccess: (data) => {
    return {
      type: actions.LIST_MEMBERSHIPS_SUCCESS,
      data,
    };
  },

  listMembershipsErr: (err) => {
    return {
      type: actions.LIST_MEMBERSHIPS_ERR,
      err,
    };
  },

  listMembersBegin: () => {
    return {
      type: actions.LIST_MEMBERS_BEGIN,
    };
  },

  listMembersSuccess: (data) => {
    return {
      type: actions.LIST_MEMBERS_SUCCESS,
      data,
    };
  },

  listMembersErr: (err) => {
    return {
      type: actions.LIST_MEMBERS_ERR,
      err,
    };
  },

  setMembershipProfileRadio: (data) => {
    return {
      type: actions.SET_MEMBERSHIP_PROFILE_RADIO,
      data,
    };
  },

  setCreateMembershipStep: (data) => {
    return {
      type: actions.SET_CREATE_MEMBERSHIP_STEP,
      data,
    };
  },

  setMembershipFormDetails: (data) => {
    return {
      type: actions.SET_MEMBERSHIP_FORM_DETAILS,
      data,
    };
  },

  setEditMembershipFormDetails: (data) => {
    return {
      type: actions.SET_EDIT_MEMBERSHIP_FORM_DETAILS,
      data,
    };
  },

  listAllAddendumsBegin: () => {
    return {
      type: actions.LIST_ALL_ADDENDUMS_BEGIN,
    };
  },

  listAllAddendumsSuccess: (data) => {
    return {
      type: actions.LIST_ALL_ADDENDUMS_SUCCESS,
      data,
    };
  },

  listAllAddendumsErr: (err) => {
    return {
      type: actions.LIST_ALL_ADDENDUMS_ERR,
      err,
    };
  },

  deleteAddendumBegin: () => {
    return {
      type: actions.DELETE_ADDENDUM_BEGIN,
    };
  },

  deleteAddendumSuccess: (data) => {
    return {
      type: actions.DELETE_ADDENDUM_SUCCESS,
      data,
    };
  },

  deleteAddendumErr: (err) => {
    return {
      type: actions.DELETE_ADDENDUM_ERR,
      err,
    };
  },

  membershipCardsBegin: () => {
    return {
      type: actions.MEMBERSHIP_CARDS_BEGIN,
    };
  },

  membershipCardsSuccess: (data) => {
    return {
      type: actions.MEMBERSHIP_CARDS_SUCCESS,
      data,
    };
  },

  membershipCardsErr: (err) => {
    return {
      type: actions.MEMBERSHIP_CARDS_ERR,
      err,
    };
  },

  deleteMembershipBegin: () => {
    return {
      type: actions.DELETE_MEMBERSHIP_BEGIN,
    };
  },

  deleteMembershipSuccess: (data) => {
    return {
      type: actions.DELETE_MEMBERSHIP_SUCCESS,
      data,
    };
  },

  deleteMembershipErr: (err) => {
    return {
      type: actions.DELETE_MEMBERSHIP_ERR,
      err,
    };
  },

  getAllWorkspaceModulesBegin: () => {
    return {
      type: actions.GET_ALL_WORKSPACE_MODULES_BEGIN,
    };
  },

  getAllWorkspaceModulesSuccess: (data) => {
    return {
      type: actions.GET_ALL_WORKSPACE_MODULES_SUCCESS,
      data,
    };
  },

  getAllWorkspaceModulesErr: (err) => {
    return {
      type: actions.GET_ALL_WORKSPACE_MODULES_ERR,
      err,
    };
  },

  generateCreditsUsageReportBegin: () => {
    return {
      type: actions.GENERATE_CREDITS_USAGE_REPORT_BEGIN,
    };
  },

  generateCreditsUsageReportSuccess: (data) => {
    return {
      type: actions.GENERATE_CREDITS_USAGE_REPORT_SUCCESS,
      data,
    };
  },

  generateCreditsUsageReportErr: (err) => {
    return {
      type: actions.GENERATE_CREDITS_USAGE_REPORT_ERR,
      err,
    };
  },

  deactivateUserBegin: () => {
    return {
      type: actions.DEACTIVATE_USER_BEGIN,
    };
  },

  deactivateUserSuccess: (data) => {
    return {
      type: actions.DEACTIVATE_USER_SUCCESS,
      data,
    };
  },

  deactivateUserErr: (err) => {
    return {
      type: actions.DEACTIVATE_USER_ERR,
      err,
    };
  },

  generateCumulativeCreditsUsageReportBegin: () => {
    return {
      type: actions.GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_BEGIN,
    };
  },

  generateCumulativeCreditsUsageReportSuccess: (data) => {
    return {
      type: actions.GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_SUCCESS,
      data,
    };
  },

  generateCumulativeCreditsUsageReportErr: (err) => {
    return {
      type: actions.GENERATE_CUMULATIVE_CREDITS_USAGE_REPORT_ERR,
      err,
    };
  },
};

export default actions;
