const actions = {
  GET_ALL_UNRESOLVED_ISSUES_BEGIN: 'GET_ALL_UNRESOLVED_ISSUES_BEGIN',
  GET_ALL_UNRESOLVED_ISSUES_SUCCESS: 'GET_ALL_UNRESOLVED_ISSUES_SUCCESS',
  GET_ALL_UNRESOLVED_ISSUES_ERR: 'GET_ALL_UNRESOLVED_ISSUES_ERR',

  GET_ALL_RESOLVED_ISSUES_BEGIN: 'GET_ALL_RESOLVED_ISSUES_BEGIN',
  GET_ALL_RESOLVED_ISSUES_SUCCESS: 'GET_ALL_RESOLVED_ISSUES_SUCCESS',
  GET_ALL_RESOLVED_ISSUES_ERR: 'GET_ALL_RESOLVED_ISSUES_ERR',

  RESOLVE_REPORTED_ISSUE_BEGIN: 'RESOLVE_REPORTED_ISSUE_BEGIN',
  RESOLVE_REPORTED_ISSUE_SUCCESS: 'RESOLVE_REPORTED_ISSUE_SUCCESS',
  RESOLVE_REPORTED_ISSUE_ERR: 'RESOLVE_REPORTED_ISSUE_ERR',

  GET_POST_DETAILS_BEGIN: 'GET_POST_DETAILS_BEGIN',
  GET_POST_DETAILS_SUCCESS: 'GET_POST_DETAILS_SUCCESS',
  GET_POST_DETAILS_ERR: 'GET_POST_DETAILS_ERR',

  getAllUnresolvedIssuesBegin: () => {
    return {
      type: actions.GET_ALL_UNRESOLVED_ISSUES_BEGIN,
    };
  },
  getAllUnresolvedIssuesSuccess: (data) => {
    return {
      type: actions.GET_ALL_UNRESOLVED_ISSUES_SUCCESS,
      data,
    };
  },
  getAllUnresolvedIssuesErr: (err) => {
    return {
      type: actions.GET_ALL_UNRESOLVED_ISSUES_ERR,
      err,
    };
  },

  getAllResolvedIssuesBegin: () => {
    return {
      type: actions.GET_ALL_RESOLVED_ISSUES_BEGIN,
    };
  },
  getAllResolvedIssuesSuccess: (data) => {
    return {
      type: actions.GET_ALL_RESOLVED_ISSUES_SUCCESS,
      data,
    };
  },
  getAllResolvedIssuesErr: (err) => {
    return {
      type: actions.GET_ALL_RESOLVED_ISSUES_ERR,
      err,
    };
  },

  resolveReportedIssueBegin: () => {
    return {
      type: actions.RESOLVE_REPORTED_ISSUE_BEGIN,
    };
  },
  resolveReportedIssueSuccess: (data) => {
    return {
      type: actions.RESOLVE_REPORTED_ISSUE_SUCCESS,
      data,
    };
  },
  resolveReportedIssueErr: (err) => {
    return {
      type: actions.RESOLVE_REPORTED_ISSUE_ERR,
      err,
    };
  },

  getPostDetailsBegin: () => {
    return {
      type: actions.GET_POST_DETAILS_BEGIN,
    };
  },
  getPostDetailsSuccess: (data) => {
    return {
      type: actions.GET_POST_DETAILS_SUCCESS,
      data,
    };
  },
  getPostDetailsErr: (err) => {
    return {
      type: actions.GET_POST_DETAILS_ERR,
      err,
    };
  },
};

export default actions;
