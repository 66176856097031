import actions from './actions';

const {
  GET_MEMBERSHIPS_BY_LOCATION_BEGIN,
  GET_MEMBERSHIPS_BY_LOCATION_SUCCESS,
  GET_MEMBERSHIPS_BY_LOCATION_ERR,

  GET_INVOICES_BEGIN,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERR,

  GET_INVOICE_CARDS_BEGIN,
  GET_INVOICE_CARDS_SUCCESS,
  GET_INVOICE_CARDS_ERR,

  GET_REVENUE_FORECAST_BEGIN,
  GET_REVENUE_FORECAST_SUCCESS,
  GET_REVENUE_FORECAST_ERR,
} = actions;

const initState = {
  membershipsByLocation: null,
  loading: false,
  error: null,
  invoices: null,
  invoiceCards: null,
  revenueForecast: null,
};
const InvoicesReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_MEMBERSHIPS_BY_LOCATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_MEMBERSHIPS_BY_LOCATION_SUCCESS: {
      return {
        ...state,
        membershipsByLocation: data,
        loading: false,
      };
    }
    case GET_MEMBERSHIPS_BY_LOCATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_INVOICES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_INVOICES_SUCCESS: {
      return {
        ...state,
        invoices: data,
        loading: false,
      };
    }
    case GET_INVOICES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_INVOICE_CARDS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_INVOICE_CARDS_SUCCESS: {
      return {
        ...state,
        invoiceCards: data,
        loading: false,
      };
    }
    case GET_INVOICE_CARDS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_REVENUE_FORECAST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_REVENUE_FORECAST_SUCCESS: {
      return {
        ...state,
        revenueForecast: data,
        loading: false,
      };
    }
    case GET_REVENUE_FORECAST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default InvoicesReducer;
