import actions from './actions';

const {
  GET_AVAILABLE_ROOMS_BEGIN,
  GET_AVAILABLE_ROOMS_SUCCESS,
  GET_AVAILABLE_ROOMS_ERR,

  CREATE_MEETING_ROOM_BEGIN,
  CREATE_MEETING_ROOM_SUCCESS,
  CREATE_MEETING_ROOM_ERR,

  EDIT_BOOKING_BEGIN,
  EDIT_BOOKING_SUCCESS,
  EDIT_BOOKING_ERR,

  GET_BOOKING_BY_ID_BEGIN,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_BOOKING_BY_ID_ERR,

  DELETE_BOOKING_BEGIN,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_ERR,

  BOOK_MEETING_ROOM_BEGIN,
  BOOK_MEETING_ROOM_SUCCESS,
  BOOK_MEETING_ROOM_ERR,

  GET_ROOM_BY_ID_BEGIN,
  GET_ROOM_BY_ID_SUCCESS,
  GET_ROOM_BY_ID_ERR,

  CANCEL_BOOKING_BEGIN,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_ERR,

  GET_ROOM_BY_SEATS_BEGIN,
  GET_ROOM_BY_SEATS_SUCCESS,
  GET_ROOM_BY_SEATS_ERR,

  LIST_ROOMS_BEGIN,
  LIST_ROOMS_SUCCESS,
  LIST_ROOMS_ERR,

  EDIT_ROOM_BEGIN,
  EDIT_ROOM_SUCCESS,
  EDIT_ROOM_ERR,

  GET_ROOM_DETAILS_BEGIN,
  GET_ROOM_DETAILS_SUCCESS,
  GET_ROOM_DETAILS_ERR,

  DELETE_ROOM_BEGIN,
  DELETE_ROOM_SUCCESS,
  DELETE_ROOM_ERR,

  ROOM_CAPACITY_LIST_BEGIN,
  ROOM_CAPACITY_LIST_SUCCESS,
  ROOM_CAPACITY_LIST_ERR,

  ROOM_CAPACITY_LIST_CHANGE,

  LIST_ROOMS_CAPACITY_LIST_CHANGE,

  GET_ROOM_FEEDBACK_BEGIN,
  GET_ROOM_FEEDBACK_SUCCESS,
  GET_ROOM_FEEDBACK_ERR,

  GET_FEEDBACK_ISSUES_BEGIN,
  GET_FEEDBACK_ISSUES_SUCCESS,
  GET_FEEDBACK_ISSUES_ERR,

  GET_FEEDBACK_COMMENTS_BEGIN,
  GET_FEEDBACK_COMMENTS_SUCCESS,
  GET_FEEDBACK_COMMENTS_ERR,

  SET_CURRENT_DATE,

  GET_ACTIVE_ROOMS_BEGIN,
  GET_ACTIVE_ROOMS_SUCCESS,
  GET_ACTIVE_ROOMS_ERR,
} = actions;

const initState = {
  allMeetingRooms: null,
  avaliableMeetingRooms: null,
  editRoomBookingData: null,
  loading: false,
  availableRoomsLoading: false,
  createMeetingRoomLoading: false,
  editMeetingRoomLoading: false,
  error: null,
  listRooms: null,
  allListRooms: null,
  getRoomDetails: null,
  getRoomDetailsLoading: false,
  roomCapacityList: null,
  listRoomsCapacityList: null,
  roomFeedback: null,
  feedbackIssues: null,
  feedbackComments: null,
  setCurrentDate: null,
  activeRooms: null,
  activeRoomsLoading: false,
};

const MeetingRoomsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_AVAILABLE_ROOMS_BEGIN:
      return {
        ...state,
        availableRoomsLoading: true,
      };
    case GET_AVAILABLE_ROOMS_SUCCESS:
      return {
        ...state,
        avaliableMeetingRooms: data,
        allMeetingRooms: data,
        availableRoomsLoading: false,
      };
    case GET_AVAILABLE_ROOMS_ERR:
      return {
        ...state,
        error: err,
        availableRoomsLoading: false,
      };

    case CREATE_MEETING_ROOM_BEGIN:
      return {
        ...state,
        createMeetingRoomLoading: true,
      };
    case CREATE_MEETING_ROOM_SUCCESS: {
      return {
        ...state,
        createMeetingRoomLoading: false,
      };
    }
    case CREATE_MEETING_ROOM_ERR:
      return {
        ...state,
        error: err,
        createMeetingRoomLoading: false,
      };
    case EDIT_BOOKING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_BOOKING_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case EDIT_BOOKING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_BOOKING_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKING_BY_ID_SUCCESS: {
      return {
        ...state,
        editRoomBookingData: data,
        loading: false,
      };
    }
    case GET_BOOKING_BY_ID_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_BOOKING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BOOKING_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_BOOKING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case BOOK_MEETING_ROOM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BOOK_MEETING_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BOOK_MEETING_ROOM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_ROOM_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ROOM_BY_ID_SUCCESS:
      return {
        ...state,
        avaliableMeetingRooms: data,
        loading: false,
      };
    case GET_ROOM_BY_ID_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CANCEL_BOOKING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        avaliableMeetingRooms: data,
        loading: false,
      };
    case CANCEL_BOOKING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_ROOM_BY_SEATS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ROOM_BY_SEATS_SUCCESS:
      return {
        ...state,
        avaliableMeetingRooms: data,
        loading: false,
      };
    case GET_ROOM_BY_SEATS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LIST_ROOMS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LIST_ROOMS_SUCCESS:
      return {
        ...state,
        listRooms: data,
        allListRooms: data,
        loading: false,
      };
    case LIST_ROOMS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case EDIT_ROOM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_ROOM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_ROOM_DETAILS_BEGIN:
      return {
        ...state,
        getRoomDetailsLoading: true,
      };
    case GET_ROOM_DETAILS_SUCCESS:
      return {
        ...state,
        getRoomDetailsLoading: false,
        getRoomDetails: data,
      };
    case GET_ROOM_DETAILS_ERR:
      return {
        ...state,
        error: err,
        getRoomDetailsLoading: false,
      };
    case DELETE_ROOM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ROOM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ROOM_CAPACITY_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ROOM_CAPACITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roomCapacityList: [
          {
            label: `View All`,
            color: 'primary',
            className: 'custom-control-yellow mb-1',
            value: 0,
            isChecked: true,
          },
          ...data.map((capacity) => {
            return {
              label: `${capacity} Seater`,
              color: 'primary',
              className: 'custom-control-yellow mb-1',
              value: capacity,
              isChecked: true,
            };
          }),
        ],
        listRoomsCapacityList: [
          {
            label: `View All`,
            color: 'primary',
            className: 'custom-control-yellow mb-1',
            value: 0,
            isChecked: true,
          },
          ...data.map((capacity) => {
            return {
              label: `${capacity} Seater`,
              color: 'primary',
              className: 'custom-control-yellow mb-1',
              value: capacity,
              isChecked: true,
            };
          }),
        ],
      };
    case ROOM_CAPACITY_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ROOM_CAPACITY_LIST_CHANGE:
      if (data.filter((d) => d.value !== 0 && !d.isChecked).length > 0) {
        const newData = data.map((d) => {
          if (d.value === 0) {
            return {
              ...d,
              isChecked: false,
            };
          } else return d;
        });

        return {
          ...state,
          roomCapacityList: newData,
          avaliableMeetingRooms: state.allMeetingRooms.filter((room) =>
            newData
              .filter((d) => d.isChecked)
              .map((d) => d.value)
              .includes(room.capacity),
          ),
        };
      } else {
        return {
          ...state,
          roomCapacityList: data,
          avaliableMeetingRooms: state.allMeetingRooms.filter((room) =>
            data
              .filter((d) => d.isChecked)
              .map((d) => d.value)
              .includes(room.capacity),
          ),
        };
      }

    case LIST_ROOMS_CAPACITY_LIST_CHANGE:
      if (data.filter((d) => d.value !== 0 && !d.isChecked).length > 0) {
        const newData = data.map((d) => {
          if (d.value === 0) {
            return {
              ...d,
              isChecked: false,
            };
          } else return d;
        });

        return {
          ...state,
          listRoomsCapacityList: newData,
          listRooms: state.allListRooms.filter((room) =>
            newData
              .filter((d) => d.isChecked)
              .map((d) => d.value)
              .includes(room.capacity),
          ),
        };
      } else {
        return {
          ...state,
          listRoomsCapacityList: data,
          listRooms: state.allListRooms.filter((room) =>
            data
              .filter((d) => d.isChecked)
              .map((d) => d.value)
              .includes(room.capacity),
          ),
        };
      }

    case GET_ROOM_FEEDBACK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ROOM_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        roomFeedback: data,
      };
    case GET_ROOM_FEEDBACK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case GET_FEEDBACK_ISSUES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_FEEDBACK_ISSUES_SUCCESS:
      return {
        ...state,
        loading: false,
        feedbackIssues: data,
      };
    case GET_FEEDBACK_ISSUES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case GET_FEEDBACK_COMMENTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_FEEDBACK_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        feedbackComments: data,
      };
    case GET_FEEDBACK_COMMENTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SET_CURRENT_DATE:
      return {
        ...state,
        setCurrentDate: data,
      };

    case GET_ACTIVE_ROOMS_BEGIN:
      return {
        ...state,
        activeRoomsLoading: true,
      };
    case GET_ACTIVE_ROOMS_SUCCESS:
      return {
        ...state,
        activeRoomsLoading: false,
        activeRooms: data,
      };
    case GET_ACTIVE_ROOMS_ERR:
      return {
        ...state,
        error: err,
        activeRoomsLoading: false,
      };

    default:
      return state;
  }
};
export default MeetingRoomsReducer;
