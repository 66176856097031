const actions = {
  GET_AVAILABLE_ROOMS_BEGIN: 'GET_AVAILABLE_ROOMS_BEGIN',
  GET_AVAILABLE_ROOMS_SUCCESS: 'GET_AVAILABLE_ROOMS_SUCCESS',
  GET_AVAILABLE_ROOMS_ERR: 'GET_AVAILABLE_ROOMS_ERR',

  CREATE_MEETING_ROOM_BEGIN: 'CREATE_MEETING_ROOM_BEGIN',
  CREATE_MEETING_ROOM_SUCCESS: 'CREATE_MEETING_ROOM_SUCCESS',
  CREATE_MEETING_ROOM_ERR: 'CREATE_MEETING_ROOM_ERR',

  EDIT_BOOKING_BEGIN: 'EDIT_BOOKING_BEGIN',
  EDIT_BOOKING_SUCCESS: 'EDIT_BOOKING_SUCCESS',
  EDIT_BOOKING_ERR: 'EDIT_BOOKING_ERR',

  GET_BOOKING_BY_ID_BEGIN: 'GET_BOOKING_BY_ID_BEGIN',
  GET_BOOKING_BY_ID_SUCCESS: 'GET_BOOKING_BY_ID_SUCCESS',
  GET_BOOKING_BY_ID_ERR: 'GET_BOOKING_BY_ID_ERR',

  DELETE_BOOKING_BEGIN: 'DELETE_BOOKING_BEGIN',
  DELETE_BOOKING_SUCCESS: 'DELETE_BOOKING_SUCCESS',
  DELETE_BOOKING_ERR: 'DELETE_BOOKING_ERR',

  BOOK_MEETING_ROOM_BEGIN: 'BOOK_MEETING_ROOM_BEGIN',
  BOOK_MEETING_ROOM_SUCCESS: 'BOOK_MEETING_ROOM_SUCCESS',
  BOOK_MEETING_ROOM_ERR: 'BOOK_MEETING_ROOM_ERR',

  GET_ROOM_BY_ID_BEGIN: 'GET_ROOM_BY_ID_BEGIN',
  GET_ROOM_BY_ID_SUCCESS: 'GET_ROOM_BY_ID_SUCCESS',
  GET_ROOM_BY_ID_ERR: 'GET_ROOM_BY_ID_ERR',

  CANCLE_BOOKING_BEGIN: 'CANCLE_BOOKING_BEGIN',
  CANCLE_BOOKING_SUCCESS: 'CANCLE_BOOKING_SUCCESS',
  CANCLE_BOOKING_ERR: 'CANCLE_BOOKING_ERR',

  GET_ROOM_BY_SEATS_BEGIN: 'GET_ROOM_BY_SEATS_BEGIN',
  GET_ROOM_BY_SEATS_SUCCESS: 'GET_ROOM_BY_SEATS_SUCCESS',
  GET_ROOM_BY_SEATS_ERR: 'GET_ROOM_BY_SEATS_ERR',

  SIGNUP_BEGIN: 'SIGNUP_BEGIN',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERR: 'SIGNUP_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  LIST_ROOMS_BEGIN: 'LIST_ROOMS_BEGIN',
  LIST_ROOMS_SUCCESS: 'LIST_ROOMS_SUCCESS',
  LIST_ROOMS_ERR: 'LIST_ROOMS_ERR',

  EDIT_ROOM_BEGIN: 'EDIT_ROOM_BEGIN',
  EDIT_ROOM_SUCCESS: 'EDIT_ROOM_SUCCESS',
  EDIT_ROOM_ERR: 'EDIT_ROOM_ERR',

  GET_ROOM_DETAILS_BEGIN: 'GET_ROOM_DETAILS_BEGIN',
  GET_ROOM_DETAILS_SUCCESS: 'GET_ROOM_DETAILS_SUCCESS',
  GET_ROOM_DETAILS_ERR: 'GET_ROOM_DETAILS_ERR',

  DELETE_ROOM_BEGIN: 'DELETE_ROOM_BEGIN',
  DELETE_ROOM_SUCCESS: 'DELETE_ROOM_SUCCESS',
  DELETE_ROOM_ERR: 'DELETE_ROOM_ERR',

  ROOM_CAPACITY_LIST_BEGIN: 'ROOM_CAPACITY_LIST_BEGIN',
  ROOM_CAPACITY_LIST_SUCCESS: 'ROOM_CAPACITY_LIST_SUCCESS',
  ROOM_CAPACITY_LIST_ERR: 'ROOM_CAPACITY_LIST_ERR',

  ROOM_CAPACITY_LIST_CHANGE: 'ROOM_CAPACITY_LIST_CHANGE',

  LIST_ROOMS_CAPACITY_LIST_CHANGE: 'LIST_ROOMS_CAPACITY_LIST_CHANGE',

  GET_ROOM_FEEDBACK_BEGIN: 'GET_ROOM_FEEDBACK_BEGIN',
  GET_ROOM_FEEDBACK_SUCCESS: 'GET_ROOM_FEEDBACK_SUCCESS',
  GET_ROOM_FEEDBACK_ERR: 'GET_ROOM_FEEDBACK_ERR',

  GET_FEEDBACK_ISSUES_BEGIN: 'GET_FEEDBACK_ISSUES_BEGIN',
  GET_FEEDBACK_ISSUES_SUCCESS: 'GET_FEEDBACK_ISSUES_SUCCESS',
  GET_FEEDBACK_ISSUES_ERR: 'GET_FEEDBACK_ISSUES_ERR',

  GET_FEEDBACK_COMMENTS_BEGIN: 'GET_FEEDBACK_COMMENTS_BEGIN',
  GET_FEEDBACK_COMMENTS_SUCCESS: 'GET_FEEDBACK_COMMENTS_SUCCESS',
  GET_FEEDBACK_COMMENTS_ERR: 'GET_FEEDBACK_COMMENTS_ERR',

  SET_CURRENT_DATE: 'SET_CURRENT_DATE',

  GET_ACTIVE_ROOMS_BEGIN: 'GET_ACTIVE_ROOMS_BEGIN',
  GET_ACTIVE_ROOMS_SUCCESS: 'GET_ACTIVE_ROOMS_SUCCESS',
  GET_ACTIVE_ROOMS_ERR: 'GET_ACTIVE_ROOMS_ERR',

  getAvailableRoomsBegin: () => {
    return {
      type: actions.GET_AVAILABLE_ROOMS_BEGIN,
    };
  },

  getAvailableRoomsSuccess: (data) => {
    return {
      type: actions.GET_AVAILABLE_ROOMS_SUCCESS,
      data,
    };
  },

  getAvailableRoomsErr: (err) => {
    return {
      type: actions.GET_AVAILABLE_ROOMS_ERR,
      err,
    };
  },

  createMeetingRoomBegin: () => {
    return {
      type: actions.CREATE_MEETING_ROOM_BEGIN,
    };
  },

  createMeetingRoomSuccess: (data) => {
    return {
      type: actions.CREATE_MEETING_ROOM_SUCCESS,
      data,
    };
  },

  createMeetingRoomErr: (err) => {
    return {
      type: actions.CREATE_MEETING_ROOM_ERR,
      err,
    };
  },

  editBookingBegin: () => {
    return {
      type: actions.EDIT_BOOKING_BEGIN,
    };
  },

  editBookingSuccess: (data) => {
    return {
      type: actions.EDIT_BOOKING_SUCCESS,
      data,
    };
  },

  editBookingErr: (err) => {
    return {
      type: actions.EDIT_BOOKING_ERR,
      err,
    };
  },

  getBookingByIdBegin: () => {
    return {
      type: actions.GET_BOOKING_BY_ID_BEGIN,
    };
  },

  getBookingByIdSuccess: (data) => {
    return {
      type: actions.GET_BOOKING_BY_ID_SUCCESS,
      data,
    };
  },

  getBookingByIdErr: (err) => {
    return {
      type: actions.GET_BOOKING_BY_ID_ERR,
      err,
    };
  },

  deleteBookingBegin: () => {
    return {
      type: actions.DELETE_BOOKING_BEGIN,
    };
  },

  deleteBookingSuccess: (data) => {
    return {
      type: actions.DELETE_BOOKING_SUCCESS,
      data,
    };
  },

  deleteBookingErr: (err) => {
    return {
      type: actions.DELETE_BOOKING_ERR,
      err,
    };
  },

  bookMeetingRoomBegin: () => {
    return {
      type: actions.BOOK_MEETING_ROOM_BEGIN,
    };
  },

  bookMeetingRoomSuccess: (data) => {
    return {
      type: actions.BOOK_MEETING_ROOM_SUCCESS,
      data,
    };
  },

  bookMeetingRoomErr: (err) => {
    return {
      type: actions.BOOK_MEETING_ROOM_ERR,
      err,
    };
  },

  getRoomByIdBegin: () => {
    return {
      type: actions.GET_ROOM_BY_ID_BEGIN,
    };
  },

  getRoomByIdSuccess: (data) => {
    return {
      type: actions.GET_ROOM_BY_ID_SUCCESS,
      data,
    };
  },

  getRoomByIdErr: (err) => {
    return {
      type: actions.GET_ROOM_BY_ID_ERR,
      err,
    };
  },

  cancelBookingBegin: () => {
    return {
      type: actions.CANCLE_BOOKING_BEGIN,
    };
  },

  cancelBookingSuccess: (data) => {
    return {
      type: actions.CANCLE_BOOKING_SUCCESS,
      data,
    };
  },

  cancelBookingErr: (err) => {
    return {
      type: actions.CANCLE_BOOKING_ERR,
      err,
    };
  },

  getRoomBySeatsBegin: () => {
    return {
      type: actions.GET_ROOM_BY_SEATS_BEGIN,
    };
  },

  getRoomBySeatsSuccess: (data) => {
    return {
      type: actions.GET_ROOM_BY_SEATS_SUCCESS,
      data,
    };
  },

  getRoomBySeatsErr: (err) => {
    return {
      type: actions.GET_ROOM_BY_SEATS_ERR,
      err,
    };
  },

  listRoomsBegin: () => {
    return {
      type: actions.LIST_ROOMS_BEGIN,
    };
  },

  listRoomsSuccess: (data) => {
    return {
      type: actions.LIST_ROOMS_SUCCESS,
      data,
    };
  },

  listRoomsErr: (err) => {
    return {
      type: actions.LIST_ROOMS_ERR,
      err,
    };
  },

  editRoomBegin: () => {
    return {
      type: actions.EDIT_ROOM_BEGIN,
    };
  },

  editRoomSuccess: (data) => {
    return {
      type: actions.EDIT_ROOM_SUCCESS,
      data,
    };
  },

  editRoomErr: (err) => {
    return {
      type: actions.EDIT_ROOM_ERR,
      err,
    };
  },

  getRoomDetailsBegin: () => {
    return {
      type: actions.GET_ROOM_DETAILS_BEGIN,
    };
  },

  getRoomDetailsSuccess: (data) => {
    return {
      type: actions.GET_ROOM_DETAILS_SUCCESS,
      data,
    };
  },

  getRoomDetailsErr: (err) => {
    return {
      type: actions.GET_ROOM_DETAILS_ERR,
      err,
    };
  },

  deleteRoomBegin: () => {
    return {
      type: actions.DELETE_ROOM_BEGIN,
    };
  },

  deleteRoomSuccess: (data) => {
    return {
      type: actions.DELETE_ROOM_SUCCESS,
      data,
    };
  },

  deleteRoomErr: (err) => {
    return {
      type: actions.DELETE_ROOM_ERR,
      err,
    };
  },

  roomCapacityListBegin: () => {
    return {
      type: actions.ROOM_CAPACITY_LIST_BEGIN,
    };
  },

  roomCapacityListSuccess: (data) => {
    return {
      type: actions.ROOM_CAPACITY_LIST_SUCCESS,
      data,
    };
  },

  roomCapacityListErr: (err) => {
    return {
      type: actions.ROOM_CAPACITY_LIST_ERR,
      err,
    };
  },

  roomCapacityListChange: (data) => {
    return {
      type: actions.ROOM_CAPACITY_LIST_CHANGE,
      data,
    };
  },

  listRoomsCapacityListChange: (data) => {
    return {
      type: actions.LIST_ROOMS_CAPACITY_LIST_CHANGE,
      data,
    };
  },

  getRoomFeedbackBegin: () => {
    return {
      type: actions.GET_ROOM_FEEDBACK_BEGIN,
    };
  },

  getRoomFeedbackSuccess: (data) => {
    return {
      type: actions.GET_ROOM_FEEDBACK_SUCCESS,
      data,
    };
  },

  getRoomFeedbackErr: (err) => {
    return {
      type: actions.GET_ROOM_FEEDBACK_ERR,
      err,
    };
  },

  getFeedbackIssuesBegin: () => {
    return {
      type: actions.GET_FEEDBACK_ISSUES_BEGIN,
    };
  },

  getFeedbackIssuesSuccess: (data) => {
    return {
      type: actions.GET_FEEDBACK_ISSUES_SUCCESS,
      data,
    };
  },

  getFeedbackIssuesErr: (err) => {
    return {
      type: actions.GET_FEEDBACK_ISSUES_ERR,
      err,
    };
  },

  getFeedbackCommentsBegin: () => {
    return {
      type: actions.GET_FEEDBACK_COMMENTS_BEGIN,
    };
  },

  getFeedbackCommentsSuccess: (data) => {
    return {
      type: actions.GET_FEEDBACK_COMMENTS_SUCCESS,
      data,
    };
  },

  getFeedbackCommentsErr: (err) => {
    return {
      type: actions.GET_FEEDBACK_COMMENTS_ERR,
      err,
    };
  },

  settingCurrentDate: (data) => {
    return {
      type: actions.SET_CURRENT_DATE,
      data,
    };
  },

  getActiveRoomsBegin: () => {
    return {
      type: actions.GET_ACTIVE_ROOMS_BEGIN,
    };
  },

  getActiveRoomsSuccess: (data) => {
    return {
      type: actions.GET_ACTIVE_ROOMS_SUCCESS,
      data,
    };
  },

  getActiveRoomsErr: (err) => {
    return {
      type: actions.GET_ACTIVE_ROOMS_ERR,
      err,
    };
  },
};

export default actions;
